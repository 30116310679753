@import '../../styles/variables.scss';

.container {
  flex: 1;
  display: flex;
  position: relative;
  max-width: $max-width-content;

  @media (max-width: $grid-breakpoint-small-max) {
    padding: 16px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    min-height: 99vh;
    margin: 5px 24px 0 24px;
  }

  @media (max-height: 650px) {
    margin-top: 0;
  }

  &.fullScreen {
    padding: 0;
    flex-direction: column;
  }
}

@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.container {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(255, 255, 255, 0.16);

  @media (max-width: $grid-breakpoint-small-max) {
    padding-bottom: 10px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    padding: 22px 0;
    align-items: center;
  }
}

.logo {
  border: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;

  @media (max-width: $grid-breakpoint-small-max) {
    display: none !important;
  }
}

.logoAcqua {
  @media (min-width: $grid-breakpoint-medium-min) {
    display: none !important;
  }

  button {
    border: 0;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
  }
}

.actions {
  display: flex;
  align-items: center;
}

.label {
  @include font-defaults-new;
  color: $color-p-white;
  margin-right: 5px;

  @media (max-width: $grid-breakpoint-small-max) {
    font-size: 12px;
    line-height: 14px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    font-size: 14px;
    line-height: 14px;
  }
}

.highlight {
  @include font-defaults-new;
  color: $color-acqua-500;
  margin-right: 5px;
  font-size: 12px;
  line-height: 14px;
}

.buttonMobile {
  border: 0;
  display: flex;
  padding: 5px 0;
  font-size: 12px;
  background-color: transparent;

  @media (min-width: $grid-breakpoint-medium-min) {
    display: none;
  }
}

.actionsDesktop {
  display: flex;
  align-items: center;

  @media (max-width: $grid-breakpoint-small-max) {
    display: none;
  }
}

.buttonDesktop {
  min-width: 146px;
  margin-left: 10px;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

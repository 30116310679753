@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.button {
  flex: 1;
  height: 56px;
  line-height: 0;

  @media (max-width: $grid-breakpoint-small-max) {
    padding: 19px 0 !important;
  }

  &:not(:last-child) {
    @media (max-width: $grid-breakpoint-small-max) {
      margin-right: 8px;
    }

    @media (min-width: $grid-breakpoint-medium-min) {
      margin-right: 16px;
    }
  }
}

.twitter {
  top: 2px !important;
  position: relative;
}

@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.container {
  width: 100%;
  display: flex;
  padding: 16px 0;
  border-radius: 12px;
  flex-direction: column;
  background-color: $color-gray-400;

  left: 0;
  top: 50px;
  position: absolute;
}

.title {
  @include font-defaults-new;
  font-size: 12px;
  color: $color-p-white-45A;
  padding: 8px 16px;
}

.providers {
  display: flex;
  flex-direction: column;
}

.button {
  flex: 1;
  gap: 16px;
  border: 0;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px 16px;

  color: $color-p-white;
  background-color: transparent;

  &:hover {
    opacity: 0.6;

    .iconArrow {
      display: flex;
    }
  }
}

.text {
  @include font-defaults-new;
  font-size: 14px;
  flex: 1;
  text-align: left;
}

.date {
  @include font-defaults-new;
  font-size: 12px;
  color: $color-gray-60;
  margin-left: 5px;
}

.iconArrow {
  display: none;
  color: $color-acqua-500;
  transform: rotate(180deg);
}

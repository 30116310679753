@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.container {
  @media (max-width: $grid-breakpoint-small-max) {
  }

  @media (min-width: $grid-breakpoint-medium-min) {
  }
}

.button {
  width: 100%;
}

.icon {
  color: $color-gray-80;
}

.iconSignIn {
  transform: rotate(180deg);
}

.input {
  margin-bottom: 15px;
}

.label,
.labelError {
  @include font-defaults-new;
  font-size: 12px;
  line-height: 14px;
}

.label {
  color: $color-gray-80;
}

.labelError {
  color: $color-s-error;
}

.labelTerms {
  @include font-defaults-new;
  font-size: 14px;
  line-height: 18px;
  color: $color-p-white;
  margin-bottom: 17px;

  a {
    color: $color-acqua-500;
  }
}
